<template>
    <div class="node-add-view">
        <div class="attention">
            说明：在当前模块中添加的子模块，是将选中子模块从之前的路径中移动到当前模块（即“move”逻辑）
        </div>
        <n-input
            placeholder="输入关键字按Enter查询模块"
            size="small"
            style="margin-top: 4px"
            v-model:value="filterKeyword"
            @keyup="onKeywordKeyup"
        >
            <template #prefix>
                <n-icon>
                    <SearchFilled />
                </n-icon>
            </template>
        </n-input>
        <n-data-table
            style="margin-top: 4px"
            size="tiny"
            :columns="tableColumns"
            :data="tableData"
            :max-height="200"
            :row-key="row => JSON.stringify(row)"
            :loading="genLoading"
            @update:checked-row-keys="onNodeSelect"
            virtual-scroll
        ></n-data-table>
        <div class="footer">
            <n-button size="tiny" type="primary" @click="complete"
                >添加</n-button
            >
        </div>
    </div>
</template>

<style lang="less" scoped>
.node-add-view {
    border: 1px solid #eee;
    border-radius: 3px;
    width: 730px;
    padding: 10px;

    .attention {
        background-color: rgba(254, 247, 237, 1);
        border: 1px solid rgba(250, 224, 181, 1);
        padding: 0 8px;
        color: #444;
    }

    .footer {
        padding: 4px 0 0;
        text-align: right;
    }
}
</style>

<script setup>
import { ref, defineEmits, defineProps } from 'vue';

import { NInput, NButton, NDataTable, NIcon } from 'naive-ui';

import { SearchFilled, AccountTreeFilled, StopFilled } from '@vicons/material';

const props = defineProps({
    nodeData: {
        type: Array,
        default() {
            return [];
        },
    },
    disabledNode: {
        type: Array,
        default() {
            return [];
        },
    },
});

const emits = defineEmits(['complete']);

let filterKeyword = ref('');

let tableColumns = ref([
    {
        type: 'selection',
    },
    {
        title: '模块',
        key: 'label',
        resizable: true,
        render(row) {
            return (
                <div style="display:flex;align-items:center">
                    <NIcon
                        v-slots={{
                            default: () => {
                                return row.leaf ? (
                                    <StopFilled />
                                ) : (
                                    <AccountTreeFilled />
                                );
                            },
                        }}
                    ></NIcon>
                    <div style="margin-left:2px">{row.label}</div>
                </div>
            );
        },
    },
    {
        title: '目前模块路径',
        key: 'key',
    },
]);
let tableData = ref([]);

function onKeywordKeyup(e) {
    if (e.code === 'Enter') {
        genTableData();
    }
}

let genLoading = ref(false);
function genTableData() {
    genLoading.value = true;
    setTimeout(() => {
        tableData.value = props.nodeData.filter(
            item =>
                item.key
                    .toLowerCase()
                    .indexOf(filterKeyword.value.toLowerCase()) > -1
        );
        genLoading.value = false;
    }, 100);
}

let selectedNodes = ref([]);

function onNodeSelect(rowKeys) {
    selectedNodes.value = rowKeys.map(rowKey => JSON.parse(rowKey));
}

function complete() {
    emits('complete', selectedNodes.value);
}
</script>
