<template>
    <div class="node-manage-view">
        <PageTitle :name="'模块管理[' + route.params.model + ']'"></PageTitle>
        <div class="content">
            <n-spin :show="nodesStore.loading">
                <div class="flex-box">
                    <div class="left">
                        <div class="filter">
                            <n-input
                                v-model:value="filterWord"
                                size="small"
                                placeholder="输入名称查找模块"
                                clearable
                            >
                                <template #prefix>
                                    <n-icon>
                                        <SearchFilled />
                                    </n-icon>
                                </template>
                            </n-input>
                        </div>
                        <div class="tree-content">
                            <n-tree
                                block-line
                                :pattern="filterWord"
                                :default-expanded-keys="defaultExpandedKeys"
                                :data="treeOpts"
                                :node-props="treeNodeProps"
                                :render-suffix="treeNodeSuffixRender"
                                :show-irrelevant-nodes="false"
                                virtual-scroll
                                style="height: 100%"
                            ></n-tree>
                        </div>
                    </div>
                    <div class="right">
                        <div class="head">
                            {{ formHeadText }}
                            <div
                                v-if="formHeadText === '创建新模块'"
                                class="attention"
                            >
                                说明：模块创建后无法人工删除，如有需要可通过移除其所有子模块，使系统自动清理空模块
                            </div>
                        </div>
                        <n-form
                            ref="nodeEditFormRef"
                            :model="nodeEditForm"
                            :rules="nodeEditFormRules"
                            label-placement="left"
                        >
                            <n-form-item label="父模块路径">
                                <span style="text-decoration: underline">{{
                                    nodeEditForm.parentPath || '无'
                                }}</span>
                            </n-form-item>
                            <n-form-item path="newName" label="模块名称">
                                <n-input
                                    v-model:value="nodeEditForm.newName"
                                    :disabled="isLeafNode"
                                ></n-input>
                            </n-form-item>
                            <n-form-item
                                v-if="!isLeafNode"
                                path="children"
                                label="子模块"
                            >
                                <n-dynamic-tags
                                    v-model:value="nodeEditForm.children"
                                    :closable="false"
                                    :render-tag="customizeTag"
                                >
                                    <template #input="{ deactivate }">
                                        <NodeAdd
                                            :node-data="treeSearchData"
                                            @complete="
                                                items => {
                                                    appendNodes(items);
                                                    deactivate();
                                                }
                                            "
                                        />
                                    </template>
                                </n-dynamic-tags>
                            </n-form-item>
                        </n-form>
                        <div class="footer">
                            <n-button
                                type="primary"
                                :disabled="isLeafNode"
                                @click="onSubmit"
                            >
                                提交
                            </n-button>
                        </div>
                    </div>
                </div>
            </n-spin>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.node-manage-view {
    .common-content;

    min-width: 1160px;

    .content {
        .standard-shadow;
        background-color: #fff;
        border: 1px solid #eee;

        .flex-box {
            display: flex;
            flex-direction: row;

            .left {
                width: 260px;
                flex-shrink: 0;
                border-right: 1px solid #eee;
                .filter {
                    padding: 10px;
                }

                .tree-content {
                    padding: 0 10px;
                    height: 80vh;
                    overflow: auto;
                }
            }

            .right {
                .head {
                    padding: 10px 0;
                    font-size: 16px;
                    font-weight: bold;
                    position: relative;

                    .attention {
                        position: absolute;
                        top: 10.5px;
                        left: 100px;
                        background-color: rgba(254, 247, 237, 1);
                        border: 1px solid rgba(250, 224, 181, 1);
                        padding: 0 8px;
                        color: #444;
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
                flex-grow: 1;
                padding: 10px 20px;
                height: 80vh;
                overflow: auto;
                .footer {
                    text-align: right;
                }
            }
        }
    }
    min-height: 90vh;
}
</style>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import {
    NButton,
    NForm,
    NFormItem,
    NInput,
    NDynamicTags,
    NTag,
    NTree,
    useMessage,
    NIcon,
    NSpin,
} from 'naive-ui';
import { AccountTreeFilled, StopFilled, SearchFilled } from '@vicons/material';
import PageTitle from '@/components/PageTitle';
import NodeAdd from './NodeAdd';
import { useNodesStore } from '@/stores/business/Nodes';
import { MVBizViewAPI } from '@/common/API';

const route = useRoute();

const defaultExpandedKeys = ['MV'];

const message = useMessage();

const nodesStore = useNodesStore();

let treeOpts = ref([]);

let treeSearchData = ref([]);

let formHeadText = ref('编辑模块');

let filterWord = ref('');

let nodeEditFormRef = ref();

let nodeEditForm = ref({
    parentPath: '',
    oldName: '',
    newName: '',
    children: [],
});

let nodeEditFormRules = ref({
    newName: {
        required: true,
        trigger: 'blur',
        message: '请输入模块名称',
    },
    children: {
        required: true,
        trigger: 'blur',
        type: 'array',
        message: '请添加至少一个子模块',
    },
});

let isLeafNode = ref(false);

let isCreateNewNode = false;

const treeNodeProps = ({ option }) => {
    return {
        style: {
            whiteSpace: 'nowrap',
        },
        onClick() {
            formHeadText.value = isCreateNewNode
                ? '创建新模块'
                : option.leaf
                ? '查看模块'
                : '编辑模块';
            let parentNodeNames = option.key.split('>');
            if (!isCreateNewNode) {
                parentNodeNames.pop();
            }
            nodeEditForm.value.parentPath = parentNodeNames.join('>');
            nodeEditForm.value.oldName = isCreateNewNode ? '' : option.label;
            nodeEditForm.value.newName = isCreateNewNode ? '' : option.label;
            isLeafNode.value = option.leaf;
            if (!option.leaf && !isCreateNewNode) {
                let originNode = getNodeItemByPath(option.key);
                nodeEditForm.value.children = originNode.children.map(item => ({
                    label: item.label,
                    value: item.key,
                    leaf: !item.children,
                }));
            } else {
                nodeEditForm.value.children = [];
            }
            // 重置创建新的子模块的状态
            isCreateNewNode = false;
        },
    };
};

// 根据字符串路径从树中找到并返回一个模块信息
function getNodeItemByPath(path) {
    let pathArr = path.split('>');
    let opts = treeOpts.value;
    for (let i = 0; i < pathArr.length; i++) {
        let filtered = opts.filter(item => item.label === pathArr[i])[0];
        if (
            !filtered.children ||
            filtered.children.length < 1 ||
            i === pathArr.length - 1
        ) {
            return filtered;
        }
        opts = filtered.children;
    }
    return null;
}

// 因创建新模块按钮会同时触发Node行点击事件，且不是容易分开，
// 所以该按钮事件只标记一个状态，在Node行点击事件中完成必要的状态初始化
function onCreateSubNodeClick() {
    isCreateNewNode = true;
}

function treeNodeSuffixRender(node) {
    if (node.option.leaf) {
        return null;
    }
    return (
        <NButton
            size="tiny"
            quaternary={true}
            type="primary"
            onClick={onCreateSubNodeClick}
        >
            创建新模块
        </NButton>
    );
}

function customizeTag(tag) {
    return (
        <NTag
            closable={false}
            v-slots={{
                default: () => tag.label,
                icon: () => (
                    <NIcon
                        size={'small'}
                        v-slots={{
                            default: () =>
                                tag.leaf ? (
                                    <StopFilled />
                                ) : (
                                    <AccountTreeFilled />
                                ),
                        }}
                    ></NIcon>
                ),
            }}
        ></NTag>
    );
}

function appendNodes(nodes) {
    nodeEditForm.value.children.push(
        ...nodes.map(item => ({ ...item, value: item.key }))
    );
}

let submitLoading = ref(false);

function onSubmit() {
    nodeEditFormRef.value.validate(errors => {
        if (errors) {
            return;
        }
        let payload = {
            tree_type: route.params.model,
            parent_path: nodeEditForm.value.parentPath,
            old_name: nodeEditForm.value.oldName || null,
            new_name: nodeEditForm.value.newName,
            children: nodeEditForm.value.children.map(item => item.value),
        };
        submitLoading.value = true;
        MVBizViewAPI.updateNode(payload).then(res => {
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            message.success('操作成功');
            nodesStore.clean();
            loadData();
        });
    });
}

function loadData() {
    nodesStore
        .getTreeStructNodes(route.params.model)
        .then(data => {
            treeOpts.value = data;
            return nodesStore.getArrayNodes(route.params.model);
        })
        .then(data => {
            treeSearchData.value = data;
        });
}
loadData();
</script>
