/**
 * @file 树形结构节点数据全局管理
 * @description 多处使用，为提升使用效率和复用性，降低网络下载成本
 */

import { defineStore } from 'pinia';

import { MVBizViewAPI } from '@/common/API';

export const useNodesStore = defineStore('standardNodesManager', {
    state: () => ({ treeName: '', data: null, isLoading: false }),
    getters: {
        loading(state) {
            return state.isLoading;
        },
    },
    actions: {
        clean() {
            this.treeName = '';
        },
        loadNodes() {
            if (!this.treeName || this.treeName === '') {
                return Promise.reject(new Error('[NodesStore]empty tree name'));
            }
            this.isLoading = true;
            return MVBizViewAPI.getStructTree(this.treeName).then(res => {
                this.isLoading = false;
                if (res.error_no !== 0) {
                    reject(new Error('[NodesStore]unknown error!'));
                    return;
                }
                this.data = res.data;
                return Promise.resolve(res.data);
            });
        },
        getTreeStructNodes(treeName) {
            if (this.treeName === treeName) {
                return Promise.resolve(genTreeNodes(this.data));
            }
            this.treeName = treeName;
            return this.loadNodes().then(data => {
                return Promise.resolve(genTreeNodes(data));
            });
        },
        getArrayNodes(treeName) {
            if (this.treeName === treeName) {
                return Promise.resolve(genArrayNodes(this.data));
            }
            this.treeName = treeName;
            return this.loadNodes().then(data => {
                return Promise.resolve(genArrayNodes(data));
            });
        },
    },
});

function genTreeNodes(nodes, layer = '') {
    return nodes.map(item => {
        let node = {
            label: item.name,
            key: layer + (layer ? '>' : '') + item.name,
            leaf: !(item.children && item.children.length > 0),
        };
        if (item.children && item.children.length > 0) {
            node.children = genTreeNodes(item.children, node.key);
        }
        return node;
    });
}

function genArrayNodes(nodesArray) {
    let resArray = [];
    function traveNode(nodes, layer = '') {
        nodes.map(item => {
            let node = {
                label: item.name,
                key: layer + (layer ? '>' : '') + item.name,
                leaf: !(item.children && item.children.length > 0),
            };
            resArray.push({ ...node });
            if (item.children && item.children.length > 0) {
                traveNode(item.children, node.key);
            }
        });
    }
    traveNode(nodesArray);
    return resArray;
}
